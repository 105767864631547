export default [
  {
    image: "ssl/login.svg",
    title: "Login Pages",
    imageAlt: "Login Pages",
  },
  {
    image: "ssl/rectangular.svg",
    title: "Intranet Sites",
    imageAlt: "Intranet Sites",
  },
  {
    image: "ssl/webmail.svg",
    title: "Webmail",
    imageAlt: "Webmail",
  },
];
