import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Landing from "../../components/landingSection";
import icons from "../../DB/icon_list_OV";
import ImageRight from "../../components/imageRight";
import ImageLeft from "../../components/imageLeft";
import Image from "../../components/imageProcessing";
import Icon_list from "../../components/SSL_COMP/icon_list";
import Cases from "../../DB/ov-cases";
import Case from "../../components/SSL_COMP/featureBlock";
// import Packages from "../../DB/plan-card-ov";
import PlanCard from "../../components/plan_card";
import LI from "../../components/plan-li";
import Feature from "../../components/SSL_COMP/FeaturesWithoutIcon";
import TrustedBrands from "../../components/SSL_COMP/trustedbrands";
import NavBar from "../../components/SSL_COMP/SSL-nav-bar";
import { graphql, useStaticQuery } from "gatsby";

/* eslint react/prop-types: 0 */
function DV() {
	const data = useStaticQuery(graphql`
		query OVQuery {
			allProducts(filter: { productID: { in: [8, 11, 12] } }) {
				nodes {
					productID
					productGroupID
					name
					description
					annually
				}
			}
		}
	`);
	return (
		<Layout>
			<SEO
				keywords={[
					`organization validation ssl`,
					`organization validation ssl certificate`,
					`extended validation ssl worth it`,
					`organization validation ssl certifictes`,
					"ssl types",
					"organization validated certificate",
					"ov ssl certificate",
					"ov ssl example",
					"best ov ssl",
					"organization validation vs extended validation",
					"ov ssl",
					"non-ev ssl certificate",
					"domain validated ssl vs extended validation ssl",
					"are all ssl certificates the same?",
					"ssl organization validation",
					"what is an ov ssl certificate?",
					"ssl validation process",
					"ssl warranty",
					"how to buy ssl",
					"dv ov ev certificate",
				]}
				title="Organization Validation SSL - SSL Certificates"
				lang="en"
				image="hostylus-card-preview.jpg"
				description="Scale up your site's protection with OVC that protects even more than standard DVC. With a padlock & HTTPS on the bar, no one has to think twice about security."
			/>

			<Landing
				image={
					<Image
						image={"sslLanding.png"}
						alt={"-blue lock with user data in the background"}
					/>
				}
				header={"Organization Validation SSL"}
				sub_header={
					"High assurance SSL shows website visitors your authenticated identity"
				}
				btnText={"pricing"}
				btnURL={"#pricing"}
				hideSecondary_btn={"hidden"}
			/>
			<NavBar />
			<section className="w-full py-10">
				<ImageRight
					header={"What is Organization Validation SSL?"}
					desc={
						<>
							<p>
								Organization Validated SSL Certificates provide instant identity
								confirmation and strong SSL protection for your website and
								business.
							</p>
							<p>
								OV SSL is an organization validated certificate that gives your
								website a step up in credibility over domain validated SSL
								Certificates. It activates the browser padlock and https, shows
								your corporate identity, and assures your customers that you
								take security very seriously. Site visitors can verify that the
								website is operated by a legitimate company and is not an
								imposter site.
							</p>
						</>
					}
					hideShopNow={"hidden"}
					hideArrow={"hidden"}
					hideLearnMore={"hidden"}
					image={
						<div className="shadow-xl w-max mx-auto mobile:w-4/5">
							<Image
								image={"ssl/secure.svg"}
								alt={"secured green bar lock"}
								className="shadow-2xl"
							/>
						</div>
					}
					btnLink="#"
				/>
			</section>
			<section className="features w-3/5 px-40 mobile:px-12 mobile:w-full">
				<h2 className="text-3xl text-black font-bold  capitalize mobile:text-center">
					Recommended Use Cases
				</h2>
				<div className="features  grid grid-cols-3 gap-3 flex justify-items-stretch  mt-6 mobile:grid-cols-1 tablet:grid-cols-2 tablet:px-16 py-4 laptop:px-6">
					{Cases.map((feature, key) => (
						<Case
							key={key}
							image={feature.image}
							header={feature.title}
							alt={feature.imageAlt}
						/>
					))}
				</div>
			</section>
			<section className="w-full p-20 my-20 mobile:p-10">
				<div className=" mx-auto grid grid-cols-3 mobile:grid-cols-1">
					<Feature
						header={"Organization Validated"}
						desc={
							"With an OV SSL Certificate, the identity of the company or organization that holds the certificate is validated, providing more trust for end users."
						}
					/>
					<Feature
						header={"Trust Site Seal"}
						desc={
							"Our SSL Certificates come with a trust seal that has been proven to increase visitor confidence and customer conversions"
						}
					/>
					<Feature
						header={"$1.25m Warranty"}
						desc={
							"OV Certificates come with a $1.25m warranty that covers data breaches caused due to a certificate flaw."
						}
					/>
				</div>
			</section>
			<section className="w-full p-10 bg-gradient-to-r from-blue-600 via-blue-700 to-blue-900 my-20 mobile:p-10">
				<section
					id="pricing"
					className="max-w-5xl mx-auto my-16 mobile:pb-3 tablet:mx-5"
				>
					<h2 className="text-4xl text-white font-bold text-center">
						Certificate Pricing
					</h2>
					<div className="grid grid-cols-3 gap-6 flex justify-items-stretch mt-6 max-w-4xl mx-auto mobile:grid-cols-1 tablet:grid-cols-2 tablet:gab-2 mobile:justify-items-center py-8">
						{data.allProducts.nodes.map(function (items, key) {
							var stripedHtml = items.description.replace(/<[^>]+>/g, "");
							var description = stripedHtml.split("\n");
							return (
								<PlanCard
									key={key}
									price={items.annually}
									header={items.name}
									productID={items.productID}
									perWhat={"yr"}
									myItems={
										<ul
											style={{
												listStyleImage:
													"url('https://ik.imagekit.io/softylus/tick_PLS8tkQNa.jpg')",
												listStylePosition: "inside",
											}}
											className="list-disc"
										>
											{description.slice(1).map((listItem, listIndex) => (
												<LI
													key={listIndex}
													LI={listItem}
													textSize={"text-sm"}
												/>
											))}
										</ul>
									}
								/>
							);
						})}
					</div>
				</section>
			</section>
			<section className="w-full py-10">
				<ImageLeft
					header={"Contains your Authenticated Organization Details"}
					desc={
						"Certificate details indicate your website is using an Organization Validated SSL Certificate and include the issuing CA, validity status and expiration date."
					}
					hideShopNow={"hidden"}
					hideArrow={"hidden"}
					hideLearnMore={"hidden"}
					image={
						<Image
							image={"cert-details-ov.png"}
							alt={"certificate details example"}
							className="shadow-2xl"
						/>
					}
				/>
			</section>

			<section className="mx-auto text-center py-10">
				<div>
					<p className="text-3xl font-bold w-full px-10">
						There&apos;s also a lot more reasons why you should consider adding
						SSL to your website:
					</p>
				</div>
				<Icon_list icons={icons} columns={"4"} />
			</section>
			<TrustedBrands />
		</Layout>
	);
}

export default DV;
