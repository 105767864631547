export default [
  {
    image: "/ssl/Encryptsensitivedata.svg",
    description: "Encrypt sensitive data",
  },
  {
    image: "/ssl/Secureonlinetransactions.svg",
    description: "Secure online transactions",
  },

  {
    image: "/ssl/Provelegitimacy.svg",
    description: "Prove legitimacy",
  },
  {
    image: "/ssl/StrongestFastestSSL.svg",
    description: "Strongest & Fastest SSL",
  },
  {
    image: "/ssl/Browser-Compatibility.svg",
    description: "99.9% Browser Compatibility",
  },
  {
    image: "/ssl/IncreaseSEOrank.svg",
    description: "Increase SEO rank",
  },
  {
    image: "/ssl/IssuedinDays.svg",
    description: "Issued in 1-2 Days",
  },
  {
    image: "/ssl/FreeReissues.svg",
    description: "Free Reissues",
  },
];
